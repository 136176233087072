import React from "react";
import UseToastMessage from "../hooks/UseToastMessage";
import ToastMessage from "../pages/utils/ToastMessage";

let ToastMessageContext;
let {Provider} = (ToastMessageContext = React.createContext());

let ToastMessageProvider = ({children}) => {
    let {show, message, variant, toastMessage, closeToastMessage} = UseToastMessage();

  return (
    <Provider value={{show, message, variant, toastMessage, closeToastMessage, children}}>
      <ToastMessage show={show} message={message} variant={variant}/>
      {children}
    </Provider>
  );
};

export {ToastMessageContext, ToastMessageProvider};
