import React from "react";
import {ToastMessageContext} from "../../contexts/ToastMessageContext";
import {Toast, ToastContainer} from "react-bootstrap";

export default function ToastMessage({show, message, variant}) {
  let {closeToastMessage} = React.useContext(ToastMessageContext);

  return (
    <ToastContainer position="bottom-center" style={{position: "fixed", marginBottom: "100px"}}>
      <Toast onClose={() => closeToastMessage()} show={show} bg={variant} delay={5000} autohide>
        <Toast.Body>
          <p className="m-0 text-lg text-white text-center">{message}</p>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  )
}
