
export function buildSyspapApiURI(path) {
  let baseUrl = process.env.REACT_APP_SYSPAP_BASE_URL + process.env.REACT_APP_SYSPAP_API_RELATIVE_URL;
  return buildURI(baseUrl, path);
}

export function buildSyspapURI(path) {
  let baseUrl = process.env.REACT_APP_SYSPAP_BASE_URL;
  return buildURI(baseUrl, path);
}

function buildURI(baseURL, path) {
  return `${baseURL}${path}`;
}

export function getErrorCodeFromAxiosError(error, defaultErrorCode = 404) {
  if (error.response) {
    return error.response.status;
  } else if (error.request) {
    return error.request.status;
  }
  return defaultErrorCode;

}
