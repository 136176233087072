import syspapAPI from "../api.js";
import {getErrorCodeFromAxiosError} from "../utils";

export async function PCRSDataLoader({params}) {
  let assessmentID = params.assessmentID;
  let data = await getPCRSAssessmentData(assessmentID);
  if (!data.success) {
    let errorCode = data.errorCode === undefined ? "500" : data.errorCode;
    throw new Response("axios error", {status: errorCode});
  }
  return data;
}

async function getPCRSAssessmentData(assessmentID) {
  return new Promise((resolve, reject) => {
    let url = "/pcrs-assessment-edit/" + assessmentID;

    syspapAPI.get(url)
      .then(function (response) {
        let data = response.data;
        resolve({
          success: data.success,
          pcrsContent: data.pcrs_children,
          patientInfoTable: data.patient_info_table,
          testName: data.test_name,
          patientRedirectUrls: data.patient_interface_urls,
          language: data.language,
        });
      })
      .catch(function (error) {
        let errorCode = getErrorCodeFromAxiosError(error);
        resolve({success: false, errorCode: errorCode});
      });
  });
}

export async function DatatableDataLoader(url, data = {}) {

  return new Promise((resolve, reject) => {
    syspapAPI.get(url, data)
      .then(function (response) {
        let data = response.data;
        resolve({success: data.success, tableRows: data.table_rows});
      })
      .catch(function (error) {
        resolve({success: false});
      })
  });
}

export async function AddPCRSEntryToSection({assessmentID, sectionCategoryID, newEntriesIDList}) {
  return new Promise((resolve, reject) => {
    let url = "/pcrs-assessment-edit/" + assessmentID + "/add-entries/" + sectionCategoryID;

    if (newEntriesIDList.length === 0) {
      resolve({sectionNewData: null});
      return;
    }

    syspapAPI.post(url, {
      "new_entries_id_list[]": newEntriesIDList,
    })
      .then(function (response) {
        let data = response.data;
        resolve({success: data.success, sectionNewData: data.new_content_for_section});
      })
      .catch(function (error) {
        resolve({success: false});
      });
  });
}


export async function AddEmptyPCRSEntryToSection(assessmentID, sectionCategoryID) {
  return new Promise((resolve, reject) => {
    let url = "/pcrs-assessment-edit/" + assessmentID + "/add-empty-entry/" + sectionCategoryID;

    syspapAPI.post(url, {})
      .then(function (response) {
        let data = response.data;
        resolve({
          success: data.success, sectionNewData: data.new_content_for_section,
          newEntryID: data.new_entry_id,
        });
      })
      .catch(function (error) {
        resolve({success: false});
      });
  });
}

export async function EditPCRSEntryText(assessmentID, entryID, newText) {
  return new Promise((resolve, reject) => {
    let url = "/pcrs-assessment-edit/" + assessmentID + "/edit-entry/" + entryID;

    syspapAPI.post(url, {
      "new_text": newText,
    })
      .then(function (response) {
        let data = response.data;
        let results = {success: data.success, newText: data.new_text}
        if (data.error) {
          results["error"] = data.error
        }
        resolve(results);
      })
      .catch(function (error) {
        resolve({success: false});
      });
  })
}

export async function DeletePCRSEntry(assessmentID, entryID) {
  return new Promise((resolve, reject) => {
    let url = "/pcrs-assessment-edit/" + assessmentID + "/delete-entry/" + entryID;

    syspapAPI.post(url)
      .then(function (response) {
        let data = response.data;
        resolve({success: data.success, sectionNewData: data.new_content_for_section});
      })
      .catch(function (error) {
        resolve({success: false});
      })
  })
}


export async function ResetPCRSEntry(assessmentID, entryID) {
  return new Promise((resolve, reject) => {
    let url = "/pcrs-assessment-edit/" + assessmentID + "/reset-entry/" + entryID;

    syspapAPI.post(url)
      .then(function (response) {
        let data = response.data;
        resolve({success: data.success, entryNewText: data.entry_new_text});
      })
      .catch(function (error) {
        resolve({success: false});
      })
  });
}


export async function SavePCRSEntryOrderForSection(assessmentID, sectionCategoryID, orderedEntryIDList) {
  return new Promise((resolve, reject) => {
    let url = "/pcrs-assessment-edit/" + assessmentID + "/save-order/" + sectionCategoryID;

    syspapAPI.post(url, {
      "section_entry_ids[]": orderedEntryIDList,
    })
      .then(function (response) {
        let data = response.data;
        resolve({success: data.success});
      })
      .catch(function (error) {
        resolve({success: false});
      });
  });
}


export async function savePCRSAssessmentSignature({assessmentID, signature}) {
  return new Promise((resolve, reject) => {
    let url = "/pcrs-assessment-edit/" + assessmentID + "/save-signature";

    syspapAPI.post(url, {
      "signature": signature,
    })
      .then(function (response) {
        let data = response.data;
        resolve({success: data.success, successURL: data.success_url});
      })
      .catch(function (error) {
        resolve({success: false});
      });
  });
}
