import React from 'react'
import Error404 from "./Error404";
import Error500 from "./Error500";
import Error403 from "./Error403";
import Error401 from "./Error401";

export default function HttpError({errorCode = "404"}) {

  let syspapHomeUrl = process.env.REACT_APP_SYSPAP_BASE_URL;

  let errorCodeMapping = {
    "401": <Error401/>,
    "403": <Error403/>,
    "404": <Error404/>,
    "500": <Error500/>
  }

  return (
    <div className="container-fluid">
      <div className="row" style={{marginTop: "10%"}}>
        <div className="col-12 text-center">
          <div className="alert alert-info" style={{marginLeft: "25%", marginRight: "25%"}}>
            <h1>Erreur {errorCode}</h1>
            {errorCodeMapping[errorCode]}
            <div>
              <a href={syspapHomeUrl} className="btn btn-warning">Revenir au syspap</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
