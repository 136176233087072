import React from "react";

export default function UseModal() {
  let [modal, setModal] = React.useState(false);
  let [modalContent, setModalContent] = React.useState("I'm the Modal Content");
  let [modalSize, setModalSize] = React.useState("lg");

  let openModal = function(content=false, size="lg"){
    setModal(true);
    setModalSize(size)
    if (content) {
      setModalContent(content);
    }
  };

  let hideCurrentModal = () => {
    setModal(false);
  };

  return {modal, openModal, hideCurrentModal, modalContent, modalSize};
};
