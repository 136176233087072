import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Modal from 'react-bootstrap/Modal';
import * as ReactDOM from "react-dom";
import {ModalContext} from "../../contexts/ModalContext";

export default function ModalContainer() {
  let {modal, modalContent, modalSize} = React.useContext(ModalContext);

  if (modal) {
    let modalSizeClass = "modal-" + modalSize;

    return ReactDOM.createPortal(
      <Modal show={modal} className={modalSizeClass}>
        {modalContent}
      </Modal>,
      document.querySelector("#modal-container")
    );
  } else {
    return null;
  }
}
