import React from "react";

export default function UseToastMessage() {
  let [show, setShow] = React.useState(false);
  let [message, setMessage] = React.useState();
  let [variant, setVariant] = React.useState("danger");

  let toastMessage = function (message, variant="danger", default_message="") {
    setShow(true);
    let messageContent = message ? message : default_message;
    setMessage(messageContent)
    setVariant(variant)
  };

  let closeToastMessage = () => {
    setShow(false);
  };

  return {show, message, variant, toastMessage, closeToastMessage};
};
