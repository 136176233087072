import React from 'react';
import PatientInfo from "./PatientInfo";
import Section from "./Section";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare, faChevronLeft, faDownload, faFileSignature} from "@fortawesome/free-solid-svg-icons";
import {Link, useLoaderData, useParams} from "react-router-dom";
import {buildSyspapURI} from "../utils";
import {Button, Dropdown} from "react-bootstrap";
import SignatureModal from "./SignatureModal";
import {ModalContext} from "../contexts/ModalContext";


export default function EditPCRS() {
  const {pcrsContent, patientInfoTable, testName, patientRedirectUrls, language} = useLoaderData();
  const {assessmentID} = useParams();
  const {openModal} = React.useContext(ModalContext);

  function openSignatureModal() {
    openModal(<SignatureModal assessmentID={assessmentID}/>);
  }

  const sendUserToSyspapURL = (url) => {
    window.location.href = buildSyspapURI(url);
  }

  return (
    <>
      <div className="header fixed-top bg-info">
        <div className="page-title-section d-flex flex-row">
          <Button variant={"secondary"} className="my-auto"
                  onClick={() => sendUserToSyspapURL(patientRedirectUrls.back)}>
            <FontAwesomeIcon icon={faChevronLeft}/>
          </Button>
          <h2 className="page-title ms-2 my-auto">Modification d'un rapport PCRS</h2>
        </div>
      </div>

      <div className="container-fluid main-content-container pl-0">
        <div className="content-panel">
          <h1 className="text-center">Rapport PCRS</h1>

          <div className="row">
            <div className="col-12">
              <table className="table text-center mx-auto w-50">
                <thead>
                <tr>
                  <th colSpan="100%">Rapport final</th>
                </tr>
                </thead>
                <tbody>
                {patientInfoTable.map((val, key) => {
                  return <PatientInfo key={"patient-info-row-" + key} title={val.name} data={val.value}/>
                })}
                </tbody>
              </table>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <h2 className="section-title">{language === "en" ? "Test name" : "Nom du test"}</h2>
              <p>{testName}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-12 sections-container">
              {pcrsContent.map((val, key) => {
                return <Section key={"section-" + val.id} id={val.id} title={val.name} largeTitle={val.large_title}
                                initialContent={val.content} spaceAfter={val.space_after} assessmentID={assessmentID}/>
              })}
            </div>
          </div>
        </div>
      </div>

      <footer className="footer fixed-bottom bg-info">
        <div className="d-flex flex-row footer-btn-container">
          <Dropdown className="my-auto">
            <Dropdown.Toggle variant="secondary">
              Ouvrir
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item disabled={patientRedirectUrls.hq === ""} as={Link}
                             to={buildSyspapURI(patientRedirectUrls.hq)} target="_blank" rel="noopener noreferrer">
                Questionnaire de santé<FontAwesomeIcon className={"ms-2"} icon={faArrowUpRightFromSquare}/>
              </Dropdown.Item>
              <Dropdown.Item as={Link} to={buildSyspapURI(patientRedirectUrls.med_profile)} target="_blank"
                             rel="noopener noreferrer">
                Dossier patient<FontAwesomeIcon className={"ms-2"} icon={faArrowUpRightFromSquare}/>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Link to={buildSyspapURI(patientRedirectUrls.pdf)} className="footer-btn btn btn-secondary ms-auto my-auto"
                target="_blank" rel="noreferrer">
            <FontAwesomeIcon className="me-2" icon={faDownload}/>PDF
          </Link>
          <Button variant={"primary"} className="footer-btn ms-2 my-auto" onClick={() => openSignatureModal()}>
            <FontAwesomeIcon className="me-2" icon={faFileSignature}/>Signer
          </Button>
        </div>
      </footer>
    </>
  )
}
