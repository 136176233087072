import React from 'react'
import {useRouteError} from 'react-router-dom'
import HttpError from "./HttpError";

export default function RouterError() {
  const error = useRouteError();

  let errorCode = "404";
  if (error) {
    errorCode = error.status;
  }

  return <HttpError errorCode={errorCode}/>
}
