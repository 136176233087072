import BaseFormModal from "./BaseFormModal";

export default function ConfirmModal({title, message, handleAccept}){

  return (
    <BaseFormModal modalTitle={title} submitBtnText={"OK"} submitFct={handleAccept}>
      <p>{message}</p>
    </BaseFormModal>
  )
}
