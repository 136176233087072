import * as React from "react";
import * as ReactDOM from "react-dom/client";

import "./index.css";
import EditPCRS from "./pages/EditPCRS";
import 'bootstrap/dist/css/bootstrap.css';
import {ModalProvider} from "./contexts/ModalContext";
import {PCRSDataLoader} from "./paths/pcrs";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {ToastMessageProvider} from "./contexts/ToastMessageContext";
import HttpError from "./pages/errors/HttpError";
import axios from "axios";
import {buildSyspapApiURI, getErrorCodeFromAxiosError} from "./utils.js";
import RouterError from "./pages/errors/RouterError";
import {Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePen} from "@fortawesome/free-solid-svg-icons";


function App() {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const axiosError = React.useRef(null);

  React.useEffect(() => {
    if (isLoggedIn) {
      return;
    }

    let urlToken = new URLSearchParams(window.location.search).get("token");
    let url = buildSyspapApiURI("/pcrs-assessment-login/" + urlToken);
    axios.get(url).then((response) => {
      let data = response.data;
      window.localStorage.setItem(process.env.REACT_APP_TITLE_STORAGE_NAME, data.user_title);
      let jwtToken = data.jwt_token;
      window.localStorage.setItem(process.env.REACT_APP_JWT_TOKEN_NAME, jwtToken);
      setIsLoggedIn(true);
      setLoading(false);
    }).catch((error) => {
      axiosError.current = getErrorCodeFromAxiosError(error);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return (
      <div className="container-fluid pl-0">
        <div className="d-flex flex-row justify-content-center" style={{height: "100vh"}}>
          <div className="my-auto">
            <p className="text-xl bg-info text-white p-4" style={{borderRadius: "50px"}}>
              Portail rapport PCRS<FontAwesomeIcon icon={faFilePen} size={"xl"} className="ms-2"/>
            </p>
            <div className="text-center mt-4">
              <Spinner animation="grow" variant={"info"}
                       style={{width: "200px", height: "200px", animationDuration: '2s'}}/>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (!isLoggedIn) {
    return <HttpError errorCode={axiosError.current}/>;
  }

  const router = createBrowserRouter([
    {
      path: "/pcrs/:assessmentID",
      loader: PCRSDataLoader,
      element: <EditPCRS/>,
      errorElement: <RouterError/>
    },
    {
      path: "*",
      element: <RouterError/>,
    },
  ]);

  return (
    <React.StrictMode>
      <ToastMessageProvider>
        <ModalProvider>
          <div id="modal-container"></div>
          <RouterProvider router={router}/>
        </ModalProvider>
      </ToastMessageProvider>
    </React.StrictMode>
  );
}

ReactDOM.createRoot(document.getElementById("root")).render(<App/>);

