import Modal from "react-bootstrap/Modal";
import React, {useContext} from "react";
import {ModalContext} from "../../contexts/ModalContext";
import {ToastMessageContext} from "../../contexts/ToastMessageContext";
import SubmitButtonWithSpinner from "./SubmitButtonWithSpinner";
import {Button} from "react-bootstrap";

export default function BaseFormModal({
                                        modalTitle, children, formID = "modal-form", submitBtnText = "Ajouter",
                                        submitFct, getFormDataFct = () => new Object(), validateForm = (data) => [],
                                        extraLeftFooterButtons = undefined,
                                      }) {
  const {hideCurrentModal} = React.useContext(ModalContext);
  const {toastMessage} = useContext(ToastMessageContext);

  async function handleSubmit() {
    let formData = getFormDataFct();

    let errorList = validateForm(formData);
    if (errorList.length > 0) {
      errorList.forEach((error) => {
        toastMessage(error, "danger");
      });
      return false;
    }
    let success = await submitFct(formData);
    if (success) {
      hideCurrentModal();
    }
    return success;
  }

  return (
    <>
      <Modal.Header closeButton={true} onHide={() => hideCurrentModal()}>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id={formID} onSubmit={handleSubmit}>
          {children}
        </form>
      </Modal.Body>
      <Modal.Footer>
        {extraLeftFooterButtons &&
          <div className="me-auto">
            {extraLeftFooterButtons}
          </div>
        }
        <SubmitButtonWithSpinner submitFct={handleSubmit} formID={formID}>
          {submitBtnText}
        </SubmitButtonWithSpinner>
        <Button variant={"secondary"} onClick={() => hideCurrentModal()}>Annuler</Button>
      </Modal.Footer>
    </>
  );
}
