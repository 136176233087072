import axios from "axios";

const syspapAPI = axios.create({
  baseURL: process.env.REACT_APP_SYSPAP_BASE_URL + process.env.REACT_APP_SYSPAP_API_RELATIVE_URL,
});

syspapAPI.interceptors.request.use((config) => {
    const jwtToken = localStorage.getItem(process.env.REACT_APP_JWT_TOKEN_NAME);
    if (jwtToken) {
      config.headers.Authorization = `Bearer ${jwtToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  });

export default syspapAPI;
