import Modal from 'react-bootstrap/Modal';
import React from "react";
import {ModalContext} from "../../contexts/ModalContext";
import {Button} from "react-bootstrap";

export default function BaseModal({modalTitle, children}) {
  let {hideCurrentModal} = React.useContext(ModalContext);

  return (
    <>
      <Modal.Header closeButton={true} onHide={() => hideCurrentModal()}>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button variant={"secondary"} onClick={() => hideCurrentModal()}>Annuler</Button>
      </Modal.Footer>
    </>
  );
}
