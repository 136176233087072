import Table from "./utils/Table";
import React, {useContext, useRef} from "react";
import BaseFormModal from "./utils/BaseFormModal";
import {AddEmptyPCRSEntryToSection, AddPCRSEntryToSection} from "../paths/pcrs";
import {ToastMessageContext} from "../contexts/ToastMessageContext";
import SubmitButtonWithSpinner from "./utils/SubmitButtonWithSpinner";
import {ModalContext} from "../contexts/ModalContext";


export default function AddNewSectionModal({assessmentID, sectionCategoryID, sectionName, setSectionDataFct}) {
  const selectedRowsList = useRef([]);
  const {toastMessage} = useContext(ToastMessageContext);
  const {hideCurrentModal} = React.useContext(ModalContext);

  const columnDef = [
    {
      id: "id",
      name: "ID",
      selector: row => row.id,
      format: row => "#" + row.id,
      width: "70px",
      searchable: true,
    },
    {
      id: "text",
      name: "Nom de la phrase",
      selector: row => row.text,
      searchable: true,
      wrap: true,
      style: {textAlign: "justify", textJustify: "inter-word"}
    },
    {
      id: "section_category_id",
      selector: row => row.section_category_id,
      omit: true,
    }
  ];

  async function addSelectedRows(formData) {
    let result = await AddPCRSEntryToSection(formData);
    if (result.success) {
      setSectionDataFct(result.sectionNewData);
    } else {
      toastMessage("Une erreur est survenue. Les phrases sélectionnées n'ont pas été ajoutées", "danger");
    }
    return result.success;
  }

  async function addEmptyEntryToSection() {
    let result = await AddEmptyPCRSEntryToSection(assessmentID, sectionCategoryID);
    if (result.success) {
      let sectionData = result.sectionNewData.map(section => {
        // The newly added empty entry is set to edit mode
        if (section.id === result.newEntryID) {
          section['edit'] = true
        }
        return section;
      })
      setSectionDataFct(sectionData);
      hideCurrentModal();
    } else {
      toastMessage("Une erreur est survenue. Les phrases sélectionnées n'ont pas été ajoutées", "danger");
    }
  }

  let extraLeftFooterButtons = <SubmitButtonWithSpinner variant={"secondary"} submitFct={addEmptyEntryToSection}>
    Insérer une entrée vide
  </SubmitButtonWithSpinner>

  function validateForm(formData) {
    let errorList = [];
    if (formData.newEntriesIDList.length === 0) {
      errorList.push("Veuillez sélectionner au moins une phrase pour l'ajouter à la section");
    }
    return errorList;
  }

  function getFormData() {
    return {
      "assessmentID": assessmentID,
      "sectionCategoryID": sectionCategoryID,
      "newEntriesIDList": selectedRowsList.current,
    }
  }

  function handleRowSelected(selection) {
    selectedRowsList.current = selection.selectedRows.map(row => row.id);
  }

  let fetchDataPath = "/pcrs-assessment-edit/" + assessmentID + "/prototype/list?sectionCategoryID=" + sectionCategoryID;

  return (
    <BaseFormModal modalTitle="Ajouter un nouveau modèle de phrase au rapport" submitFct={addSelectedRows}
                   getFormDataFct={getFormData} validateForm={validateForm}
                   extraLeftFooterButtons={extraLeftFooterButtons}>
      <div className="row">
        <div className="col-12">
          <p className="m-0">La ou les phrase(s) sélectionnée(s) seront ajouté(s) à la section «{sectionName}»</p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Table columnDef={columnDef} selectableRows={true} handleRowSelectedChanges={handleRowSelected}
                 fetchDataPath={fetchDataPath}/>
        </div>
      </div>
    </BaseFormModal>
  )
}
