import React from "react";
import ModalContainer from "../pages/utils/ModalContainer";
import useModal from "../hooks/UseModal";

let ModalContext;
let {Provider} = (ModalContext = React.createContext());

let ModalProvider = ({children}) => {
  let {modal, openModal, hideCurrentModal, modalContent, modalSize} = useModal();
  return (
    <Provider value={{modal, openModal, hideCurrentModal, modalContent, modalSize}}>
      <ModalContainer/>
      {children}
    </Provider>
  );
};

export {ModalContext, ModalProvider};
