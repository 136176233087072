import DataTable from 'react-data-table-component';
import React, {useCallback, useEffect, useRef, useState} from "react";
import {DatatableDataLoader} from "../../paths/pcrs";
import {ToastMessageContext} from "../../contexts/ToastMessageContext";
import {Spinner} from "react-bootstrap";

export default function Table({columnDef, selectableRows = false, handleRowSelectedChanges = null, fetchDataPath}) {
  const completeRowList = useRef([])
  const [data, setData] = useState(undefined);

  let {toastMessage} = React.useContext(ToastMessageContext);


  const fetchData = useCallback(async () => {
    const result = await DatatableDataLoader(fetchDataPath);
    if (result.success) {
      completeRowList.current = result.tableRows;
    } else {
      toastMessage("Une erreur est survenue.  Les données n'ont pas pu être récupérées", "danger");
      completeRowList.current = [];
    }
    setData(completeRowList.current);
  }, [fetchDataPath, toastMessage]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (e) => {
    let searchableColumns = columnDef.filter((column) => column.searchable);

    const newRows = completeRowList.current.filter((row) => {
      let searchResults = searchableColumns.map((column) => {
        return column.selector(row)
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase());
      });
      return searchResults.includes(true);
    });
    setData(newRows);
  };


  function onSelectedRowsChange(selection) {
    if (handleRowSelectedChanges) {
      handleRowSelectedChanges(selection);
    }
  }

  if (data === undefined) {
    return (
      <div className="row">
        <div className="col-12 text-center my-4">
          <Spinner animation={"border"}/>
        </div>
      </div>
    )
  }

  let initSearch = columnDef.some((column) => column.searchable);

  return (
    <div>
      {initSearch &&
        <div className="d-flex flex-row justify-content-end">
          <input type="search" className="form-control-sm" placeholder="Rechercher..."
                 onChange={handleSearch}/>
        </div>
      }
      <DataTable columns={columnDef} data={data} noDataComponent={"Aucun résultats"} fixedHeader pagination striped
                 selectableRows={selectableRows} onSelectedRowsChange={onSelectedRowsChange}
                 className="datatable" fixedHeaderScrollHeight={"100%"} selectableRowsHighlight={selectableRows}/>
    </div>
  )
}
