import SignatureCanvas from "react-signature-canvas";
import {useContext, useRef} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRefresh} from "@fortawesome/free-solid-svg-icons";
import BaseFormModal from "./utils/BaseFormModal";
import {savePCRSAssessmentSignature} from "../paths/pcrs";
import {ToastMessageContext} from "../contexts/ToastMessageContext";
import {buildSyspapURI} from "../utils";
import {Button} from "react-bootstrap";

export default function SignatureModal({assessmentID}) {
  const signatureCanvasRef = useRef(null);
  const {toastMessage} = useContext(ToastMessageContext);

  const clearSignature = () => {
    signatureCanvasRef.current.clear();
  };

  const saveSignature = async (formData) => {
    let result = await savePCRSAssessmentSignature(formData)
    if (!result.success) {
      toastMessage("Une erreur est survenue lors de la sauvegarde de la signature", "danger");
    } else {
      toastMessage("La signature a été sauvegardée avec succès", "success");
      setTimeout(() => {
        redirectToSyspapOnSignatureSaved(result.successURL);
      }, 1000);
    }
    return result.success;
  };

  const getSignatureData = () => {
    let signatureData = signatureCanvasRef.current.toDataURL();
    if (signatureCanvasRef.current.isEmpty()) {
      signatureData = null;
    }
    return {
      "signature": signatureData,
      "assessmentID": assessmentID,
    }
  }

  const validateSignature = (data) => {
    let errors = [];
    if (data.signature === null) {
      errors.push("Veuillez signer le rapport");
    }
    return errors;
  }

  const redirectToSyspapOnSignatureSaved = (successUrl) => {
    let uri = buildSyspapURI(successUrl);
    window.location.replace(uri);
  }

  const signatory = window.localStorage.getItem(process.env.REACT_APP_TITLE_STORAGE_NAME);

  return (
    <BaseFormModal modalTitle="Signer le rapport" submitBtnText="Signer" submitFct={saveSignature}
                   getFormDataFct={getSignatureData} validateForm={validateSignature}>
      <div className="row">
        <div className="col-12 text-center signature-canvas-container">
          <SignatureCanvas ref={signatureCanvasRef} penColor='black' canvasProps={{className: "signature-canvas"}}/>
          <Button variant={"link"} type="button" onClick={clearSignature} className="clear-canva-btn">
            <FontAwesomeIcon icon={faRefresh}/>
          </Button>
        </div>
      </div>
      <div className="row ms-1">
        <div className="col-6">
          <p className="m-0"><span className="fw-bold">Signataire: </span>{signatory}</p>
        </div>
      </div>
    </BaseFormModal>
  )
}
