import BaseModal from "./utils/BaseModal";
import Table from "./utils/Table";
import React from "react";

export default function SectionRowHistoryModal({assessmentID, entryID}) {

  const columnDef = [
    {
      id: "id",
      name: "ID",
      selector: row => row.id,
      omit: true,
    },
    {
      id: "date",
      name: "Date",
      selector: row => row.date,
      width: "10%",
      wrap: true,
    },
    {
      id: "content",
      name: "",
      selector: row => row.content,
      wrap: true,
      format: row => <div dangerouslySetInnerHTML={{__html: row.content}}/>,
      width: "850px",
    },
    {
      id: "modified_by",
      name: "Modifié par",
      selector: row => row.modified_by,
      wrap: true,
    },
  ];

  let modalTitle = "Historique pour la phrase #" + entryID;
  let fetchDataPath = "/pcrs-assessment-edit/" + assessmentID + "/history/" + entryID;

  return (
    <BaseModal modalTitle={modalTitle}>
      <Table columnDef={columnDef} selectableRows={false} fetchDataPath={fetchDataPath}/>
    </BaseModal>
  )
}
